<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    title: string
    isDurationVisible: boolean
    category: string
    tag: string
    count: number
    itemsPerPage?: number
    notCollapsedRows?: number
  }>(),
  {
    title: '',
    isDurationVisible: false,
    category: '',
    tag: '',
    count: 5,
    itemsPerPage: 10,
    notCollapsedRows: 1,
  }
)
const filters = { category: props.category, tag: props.tag }
const { algoliaResults, pending: loading } = await useBoxEvents(
  filters,
  props.itemsPerPage
)
</script>
<template>
  <div>
    <ProductShowcase
      data-test-id="giftcard-events-section"
      :products="algoliaResults?.results?.[0]?.hits"
      image-loading="eager"
      :collapse-products="true"
      :title-h-class="`legacy-h2`"
      :mobile-horizontal-scroll="true"
      :title="title"
      :not-collapsed-rows="notCollapsedRows"
      :is-duration-visible="false"
      algolia-insights-label="Gift Ideas For You - Homepage"
      class="mt-8 md:mt-16"
    />
  </div>
</template>
